<template>

  <el-dialog class="buynow"  v-model="isShowBuyNow" title="Checkout" @close="showModal" >
    <template #header>
      <div class="dialog-title">
        Checkout
      </div>
    </template>
    <div v-loading="paying">
      <el-row class="mb-4" >
        <label class="font-weight-bold  col-12 input_label" >Quantity</label>
        <div class="col-12">
          <el-input-number size="large" v-model="quantity" style="width: 100%;" :min="1"
                           :max="plan.limitTime"></el-input-number>
        </div>
      </el-row>

      <el-row class="mb-4">
        <label class="font-weight-bold  input_label" >Password</label>
        <el-input size="large" v-model="password" title="password" type="password" show-password>
        </el-input>
      </el-row>
      <el-alert class="mt-3" v-if="alert.show" :title="alert.text" :closable="false" show-icon
                type="error"></el-alert>
<!--      <el-alert class="mt-2" v-else title="Default payment password: 666777" :closable="false" show-icon-->
<!--                type="info"></el-alert>-->

    </div>

    <template #footer>
      <div class="row  d-md-flex align-items-center justify-content-center justify-content-md-between">
        <div class="col-12 col-md-6">
          <div class="amount">
            <div class="title">
              Amount
            </div>
            <div class="content">
              = {{this.plan.amount * this.quantity}} USD
              <svg-icon icon-class="USDT-TRC20" style="font-size: 19px;margin-left: 12px"></svg-icon>


<!--              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path-->
<!--                    d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"-->
<!--                    fill="#00A478"/>-->
<!--                <path d="M15.9894 17.4429V13.3308H10.4715V9.72632H25.8894V13.3812H20.3715V17.4429H15.9894Z" fill="white"/>-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                      d="M8.1 17.7921C8.1 16.4808 12.5118 15.4125 18 15.4125C23.4882 15.4125 27.9 16.4808 27.9 17.7921C27.9 19.1034 23.4882 20.1717 18 20.1717C12.5118 20.1717 8.1 19.1034 8.1 17.7921ZM27.0792 17.7921C26.7201 17.3061 23.7447 15.8013 18 15.8013C12.2553 15.8013 9.2799 17.2575 8.9208 17.7921C9.2799 18.2772 12.2553 19.0062 18 19.0062C23.796 19.0062 26.7201 18.2772 27.0792 17.7921Z"-->
<!--                      fill="white"/>-->
<!--                <path-->
<!--                    d="M20.3715 18.7127V15.8696C19.6497 15.8183 18.8766 15.7679 18.1035 15.7679C17.3817 15.7679 16.6608 15.7679 15.9903 15.8183V18.6614C16.6248 18.7613 17.3898 18.7802 18.1035 18.7802C18.8163 18.7802 19.6497 18.7631 20.3715 18.7127ZM18 20.3777C17.2782 20.3777 16.659 20.3579 15.9894 20.3075V27.9017H20.3202V20.2859C19.5984 20.3372 18.7731 20.3777 18 20.3777Z"-->
<!--                    fill="white"/>-->
<!--              </svg>-->

            </div>
          </div>
        </div>


        <!--        <b-button variant="danger" class="float-left" @click="showPay = false; paymentPwd = '';alert.show = false">-->
        <!--          Back-->
        <!--        </b-button>-->
<!--        <el-button class="btn btn-dark ml-2" @click="pay"  :disabled="!paymentPwd" :loading="paying">Pay</el-button>-->
        <div class="col-12 col-md-6 mt-5 mt-md-0 d-flex justify-content-center justify-content-md-end">
          <button href="#"  class="btn btn-dark " style="width: 211px;" @click="pay" :class="{'disabled': !password || paying}" :disabled="!password || paying">Pay</button>
        </div>
        </div>
    </template>
  </el-dialog>
</template>


<script>
import planApi from '../../apis/planApi';
import date from '../../date';

export default {
  name: "BuyNow",
  components: {},
  props: {
    plan: Object,
    showBuyNow: Boolean
  },
  data() {
    return {
      password: "",
      quantity: 1,
      alert: {
        text: "",
        show: false
      },
      paying: false,
      isShowBuyNow: this.showBuyNow,
      hasEverPurchase: false,
      transForm: {
        pageNum: 1,
        pageSize: 5
      },
    }
  },
  watch: {
    showBuyNow(val) {
      // console.log('showBuyNow:' + val)
      this.isShowBuyNow = val
    },
    isShowBuyNow(val) {
      // console.log('isShowBuyNow:' + val)
      if (!val) {
        this.$emit("buyNowModal")
      }
    },
    // quantity(val) {
    //   // console.log(this.plan.amount)
    //   if (this.quantity !== 1) {
    //     console.log('amount:' + this.plan.amount)
    //     // console.log('quantity:' + val)
    //     this.getAmount =  this.plan.amount * val
    //   } else {
    //     this.getAmount = this.plan.amount * 1
    //   }
    // },
  },
  // filters: {
  //   getDate(data) {
  //     return date.getDate(data);
  //   }
  // },
  created: function () {
    // console.log('aaa:' + this.plan.id)
    // if (this.showPay) {
    //   this.isShow = true
    // }
  },
  mounted() {

  },
  methods: {
    getDate(data) {
      return date.getDate(data);
    },
    showModal() {
      // this.$emit("buyNowModal")
      this.isShowBuyNow = false
      this.quantity = 1
      // this.alert.text = ''
      this.alert.show = false
      this.password = ''
    },
    pay() {
      //密码不得为空
      if (this.password === "") {
        this.alert.text = "Password cannot be empty"
        this.alert.show = true
        return
      }
      //支付请求开始
      this.paying = true
      // hasEverPurchase
      // planApi.mine(this.transForm, res => {
      //   const myOrders = res.data?.result || [];
      //   if(myOrders.length > 0){
      //     this.hasEverPurchase = true
      //   }
      // })
      planApi.buy({
        planId: this.plan.id,
        password: btoa(this.password),
        quantity: this.quantity
      }, res => {
        //请求返回内容
        this.paying = false
        if (res.code !== 200) {
          this.alert.text = res.msg
          this.alert.show = true
        } else {
          if(this.plan.id !== 1){
            this.trackPurchaseEvent()
          }
          //支付成功关闭窗口
          this.$message.success({
            message: "success"
          })
          this.alert.show = false
          // this.isShow = false
          // this.$emit("modal")
          this.isShowBuyNow = false
        }
      })
    },
    trackPurchaseEvent(){
      if (window.gtag) {
        window.gtag ('event', 'purchase', { value: 1, currency: "USD"});
      }
      // gtag("event", "purchase", {value: 0.00, currency: "USD" });
      this.$gtm.trackEvent({
        event: 'purchase',
        value: 1.00,
        currency: 'USD'
      });
    },

    transform(html) {
      if (!html) return '';
      return html.replace(/\\n/g, "<br/>")
    }
  },
  // computed: {
     // getAmount: function (){
     //   if (this.quantity !== 1) {
     //     console.log('amount:' + this.plan.amount)
     //     console.log('quantity:' + this.quantity)
     //     return this.plan.amount * this.quantity
     //   } else {
     //     return this.plan.amount * 1
     //   }
     // }
  // }
}
</script>

<style  lang="scss">
.dialog-title{
  font-size: 20px;
  //font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1C2A46;
}
.buynow {
  width: 90%;
  max-width: 650px;
}
</style>


<style scoped lang="scss">
.input_label{
  font-size: 15px;
  //font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333947;
  line-height: 21px;
  margin-bottom: 10px;
}
.amount{
  text-align: left;
  font-size: 18px;
  //font-family: PingFangSC-/Medium, PingFang SC;
  font-weight: 600;
  line-height: 25px;
  .title{
    //height: 50px;
    color: #1C2A46 ;

  }
  .content{
    color: #198754;
    display: flex;
    align-items: center;
  }

}

.el-usdt {

  background: url('../../assets/icon/usdt.svg') center center no-repeat;
}


</style>

