<template>
  <section class="related-product-section section-space-b" >
    <div class="container">
      <!-- section heading -->
      <SectionHeading classname="text-center" text="Trending Contracts"></SectionHeading>
      <!-- product -->
      <swiper
          :slides-per-view="4"
          :breakpoints="{
              0: {
                  slidesPerView: 1
              },
              767: {
                  slidesPerView: 2
              },
              992: {
                  slidesPerView: 3
              },
              1200: {
                  slidesPerView: 4
              }
            }"
          :pagination="{ clickable: true }">
        <swiper-slide v-for="(product, index) in products" :key="product.id">
          <div style="margin: -15px" class="products-box">
<!--            <ProductsItem :product="product" :index="index" :is-related="false"></ProductsItem>-->
            <ProductsSix :product="product" :index="index" :is-related="false"></ProductsSix>

          </div>
        </swiper-slide>
      </swiper>
    </div><!-- .container -->
  </section><!-- end related-product-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
// core version + navigation, pagination modules:
import SwiperCore, {Pagination} from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import planApi from "@/apis/planApi";
import ProductsItem from "@/components/n1/ProductsItem";
import ProductsSix from "@/components/n1/ProductsSix"
import SectionHeading from "@/components/n1/SectionHeading"

export default {
  name: 'RelatedProduct',
  components: {
    Swiper,
    SwiperSlide,
    ProductsItem,
    ProductsSix,
    SectionHeading
  },
  data() {
    return {
      SectionData,
      loading: false,
      planForm: {
        limitTime: '',
        name: '',
        recommend: true,
        pageNum: 1,
        pageSize: 12
      },
      products: []
    }
  },
  mounted() {
    this.loading = true;
    planApi.list(this.planForm, res => {
      console.log(res)
      this.loading = false;
      this.products = (res.data?.result || []).filter(p => p.status === 1)  // status 1 appear    2 disappear
    })
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.related-product-section{
  background: rgb(247, 249, 251);
}
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.author-linkm,
.card-price-wrap {
  z-index: 2;
  position: relative;
}

.products-box {
  padding: 0.75rem;
}
</style>
